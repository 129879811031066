.DateTime {
  white-space: nowrap !important;
  max-width: 270px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.DateTime::-webkit-inner-spin-button,
.DateTime::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

@media (max-width: 640px) {
  .ClaimWorkflowListItem .DateTime {
    max-width: 130px;
  }
}
