.Base {
  /* no styles */
}

.BaseNarrow {
  max-width: 500px;
}

.BaseFadeIn {
  animation-duration: 0.5s;
  animation-name: fadein;
  transition-timing-function: ease-out;
}
