/* purgecss start ignore */
.Nav {
  z-index: 10;
  animation-duration: 0.25s;
  animation-name: slidein;
}

.tenant-tesla .Nav {
  border-bottom: 1px solid #383838;
}

.Twilio-MainHeader-default svg {
  display: none !important;
}

@media (max-width: 640px) {
  .Twilio.Twilio-MainContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
    width: 100%;
    max-height: none;
    height: 100%;
    z-index: 999;
  }

  .Twilio.Twilio-EntryPoint {
    display: none;
  }

  .Twilio-Icon.Twilio-Icon-Close {
    transform: scale(1.5);
    margin-right: 5px;
  }

  .Twilio-MainHeader-end {
    overflow: hidden;
  }
}
/* purgecss end ignore */
