/* purgecss start ignore */
.AssuredModal-background-enter.AssuredModal-background-enter-active,
.AssuredModal-background-enter-done {
  @apply opacity-100 !important;
}

.AssuredModal-background-exit.AssuredModal-background-exit-active {
  @apply opacity-0;
}

.AssuredModal-enter.AssuredModal-enter-active,
.AssuredModal-enter-done {
  @apply opacity-100 scale-100 !important;
}

.AssuredModal-exit.AssuredModal-exit-active {
  @apply opacity-0 scale-95;
}
/* purgecss end ignore */
