/* purgecss start ignore */
.PhoneInput {
  --PhoneInput-color--focus: #718096;
  --PhoneInputCountrySelectArrow-color--focus: #718096;
  --PhoneInputCountryFlag-borderColor--focus: #718096;

  .PhoneInputInput {
    outline: 0;
  }

  .PhoneInputCountryIconUnicode {
    transform: scale(1.5);
  }

  .PhoneInputCountry {
    margin-right: 0.6rem;
  }
}
/* purgecss end ignore */
