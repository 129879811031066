.Seatmap {
  --normal-color: #a0aec0;
  --light-color: #cbd5e0;
  --active-color: #718096;
}

.Seatmap.SeatmapSmall svg {
  width: 50%;
  margin: 0 auto;
}

.Seatmap svg {
  fill: var(--light-color);
  height: auto;
  max-width: 100%;
}

.Seatmap.interactive svg {
  fill: var(--normal-color);
}

.Seatmap.interactive g[id] {
  cursor: pointer;
  transition: 0.05s all ease;
}

/* .Seatmap.interactive g[id]:hover {
  fill: var(--active-color);
} */

.Seatmap:not(.SeatmapAllowAnySeat) g#DRIVER {
  user-select: none;
  fill: var(--light-color);
  cursor: initial;
}
