.ClaimWorkflow {
  max-width: 500px;
  width: 90vw;
  min-height: 250px;
  transition: all 0.2s ease-in-out;
}

.ClaimWorkflowProgress {
  max-width: 400px;
  width: 80vw;
}

.ClaimWorkflowColorButton {
  width: 60px;
  height: 60px;
}

.ClaimWorkflowTitle {
  animation-duration: 0.75s;
  animation-name: fadein;
  transition-timing-function: ease-out;
}

.ClaimWorkflowInner {
  animation-duration: 1s;
  animation-name: fadein_delay;
  transition-timing-function: ease-out;
}

.ClaimWorkflowInnerDelay {
  animation-duration: 1.75s;
  animation-name: fadein_delay;
  transition-timing-function: ease-out;
}

.ClaimWorkflowInnerBuilder {
  animation-duration: 0.5s;
  animation-name: fadein_delay;
  transition-timing-function: ease-out;
}
